import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import FooterContactDetails from "./footerContactDetails";
import FooterForm from "./footerForm";
import FooterSocial from "./footerSocial";
import FooterTitle from "./footerTitle";

const footerStyles = {
    height: "auto"
};

const formStyle = {
    backgroundColor: "black"
};

const FooterMain = () => {
    return (
        <div className="bg-light text-dark py-sm-5">
            <div id="contact-us" className="top"/>
            <Container>
                <Row className="">   
                    <Col className="bg-white" xs={12} lg={5}>
                        <FooterContactDetails/>
                    </Col>       
                    <Col className="bg-dark" xs={12} lg={7}>
                        <FooterForm/> 
                    </Col>     
                    <Col className="p-0" xs={12}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4713.712324199051!2d-1.4420657225248932!3d53.792047558022965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487943344e22d23f%3A0xafd078e45a177853!2sColton%2C%20Leeds!5e0!3m2!1sen!2suk!4v1657789944149!5m2!1sen!2suk" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterMain;