import * as React from "react";
import {Col, Container, Row, Stack} from "react-bootstrap";
import OurWorkCarousel from "./ourWorkCarousel";

const OurWorkMain = () => {
    return (
        <>
            <Container className="container-lg p-5 h-auto text-center">
                <div id="our-work" className="top"/>
                <Stack gap={4}>
                    <h1>Explore Our Recent Work</h1>
                </Stack>
            </Container>
            <OurWorkCarousel/>
        </>
    );
}

export default OurWorkMain;